import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollSmoother from 'gsap/ScrollSmoother';
gsap.registerPlugin(Draggable, ScrollTrigger, ScrollSmoother);

// Инициализация ScrollSmoother
function initSmoothScroll() {
  ScrollSmoother.create({
    wrapper: "#smooth-wrapper", // Обёртка всего контента
    content: "#smooth-content", // Контент, который прокручивается
    smooth: 2,                // Значение сглаживания (чем больше, тем плавнее)
    effects: true,              // Включает эффекты ScrollTrigger
  });
}

// Анимация изображений
function initImageAnimations() {
  const blocks = document.querySelectorAll("article");

  blocks.forEach((block) => {
    gsap.timeline({
      scrollTrigger: {
        trigger: block,
        start: "70% top",
        end: "bottom top",
        scrub: true,
        invalidateOnRefresh: true,
      },
    })
      .fromTo(
        block,
        { autoAlpha: 1, scale: 1, filter: "blur(0)" },
        {  scale: 0.7 }
      );
  });
}

// Инициализация
document.addEventListener("DOMContentLoaded", () => {
  initSmoothScroll();     // Плавный скролл
  initImageAnimations();  // Анимации блоков

  // Обновляем ScrollTrigger после загрузки
  window.addEventListener("load", () => {
    ScrollTrigger.refresh();
  });
});


//SPLIDE 
document.addEventListener('DOMContentLoaded', function () {
  const sliders = document.querySelectorAll('.smallSlider'); // Найти все слайдеры с классом sliderSmall

  sliders.forEach((slider) => {
    new Splide(slider, {
      type: 'loop',
      arrows: false,
      perPage: 1,
      drag: true,
      // autoplay: true,
      // interval: 3000,
    }).mount();
  });
});




//DragNDrop Stickers
document.addEventListener('DOMContentLoaded', function () {
  const containers = document.querySelectorAll('article'); // Все статьи с карточками

  containers.forEach(container => {
    const draggableItems = container.querySelectorAll('.draggableSticker');

    const containerWidth = container.clientWidth * 0.8; // Ограничиваем ширину 80% контейнера
    const containerHeight = container.clientHeight; // Полная высота контейнера

    draggableItems.forEach((item) => {
      const isMobile = window.innerWidth < 768;

      // Устанавливаем ограничения для высоты
      const minHeightMobile = window.innerHeight * 0.1; // 10vh
      const maxHeightMobile = window.innerHeight * 0.2; // 20vh
      const minHeightDesktop = containerHeight * 0.15; // 15% высоты контейнера
      const maxHeightDesktop = containerHeight * 0.25; // 25% высоты контейнера

      // Выбираем ограничения для высоты в зависимости от устройства
      const minHeight = isMobile ? minHeightMobile : minHeightDesktop;
      const maxHeight = isMobile ? maxHeightMobile : maxHeightDesktop;

      // Генерация случайной высоты с учетом ограничений
      const randomHeight = Math.random() * (maxHeight - minHeight) + minHeight;

      // Устанавливаем случайную высоту элемента
      item.style.height = `${randomHeight}px`;

      // Ждём, пока браузер рассчитает размеры элемента
      const itemWidth = item.offsetWidth;
      const itemHeight = item.offsetHeight;

      // Генерация случайных координат внутри ограниченного контейнера
      const randomX = Math.random() * (containerWidth - itemWidth); // 80% ширины
      const randomY = Math.random() * (containerHeight - itemHeight); // Полная высота

      // Генерация случайного угла поворота от -15 до 15 градусов
      const randomRotation = Math.random() * 30 - 15;

      // Устанавливаем начальные параметры для элемента
      gsap.set(item, {
        x: randomX,
        y: randomY,
        rotation: randomRotation,
        zIndex: Math.floor(Math.random() * 100) + 1
      });

      // Создаем Draggable для элемента
      Draggable.create(item, {
        type: 'x,y',
        bounds: container,         // Ограничиваем перемещение областью контейнера
        inertia: true,             // Добавляем инерцию
        zIndexBoost: true,         // Перемещаем элемент на передний план при перетаскивании
        onDragEnd: function () {
          console.log('Dragging ended for', item.id);
        },
      });
    });
  });
});



//DragNDrop gallery
document.addEventListener('DOMContentLoaded', function () {
  // Выбираем все контейнеры с нужным классом или атрибутом
  const galleries = document.querySelectorAll('.draggable-gallery'); // класс для всех галерей

  galleries.forEach(gallery => {
    const images = gallery.querySelectorAll('.ddItem'); // Все элементы внутри текущей галереи
    const galleryWidth = gallery.offsetWidth;
    const galleryHeight = gallery.offsetHeight;

    // Ограничение максимальной ширины
    const maxWidth = galleryWidth * 0.3;

    // Установка минимальной ширины в зависимости от устройства
    const minWidth = window.innerWidth < 768 ? galleryWidth * 0.70 : galleryWidth * 0.13;

    images.forEach((image) => {
      const originalWidth = image.clientWidth;
      const originalHeight = image.clientHeight;

      // Генерация случайного размера между minWidth и maxWidth
      let randomWidth = Math.random() * (maxWidth - minWidth) + minWidth;
      let randomHeight = (randomWidth / originalWidth) * originalHeight;

      // Генерация случайных координат с учётом нового размера
      const randomX = Math.random() * (galleryWidth - randomWidth);
      const randomY = Math.random() * (galleryHeight - randomHeight);

      // Сохраняем координаты в data-атрибуты, чтобы использовать их позже
      image.dataset.x = randomX;
      image.dataset.y = randomY;

      // Устанавливаем начальные позиции, размеры и z-index
      gsap.set(image, {
        x: randomX,
        y: randomY,
        width: randomWidth,       // Применяем случайную ширину с ограничением
        height: randomHeight,     // Применяем соответствующую высоту с ограничением
        zIndex: Math.floor(Math.random() * 100) + 1  // Устанавливаем z-index для каждого элемента
      });

      // Инициализация Draggable с учётом новых размеров
      Draggable.create(image, {
        bounds: gallery,          // Ограничиваем перетаскивание границами текущей галереи
        zIndexBoost: true,        // Выдвигаем элемент на передний план при перетаскивании
        inertia: true,            // Добавляем инерцию
        onPressInit: function () {
          // Перед началом перетаскивания сохраняем текущие координаты
          this.startX = parseFloat(image.dataset.x);
          this.startY = parseFloat(image.dataset.y);
        },
        onDrag: function () {
          // Обновляем координаты при перетаскивании
          image.dataset.x = this.x;
          image.dataset.y = this.y;
        }
      });
    });

    // Слушаем клик на картинке, чтобы выдвинуть её на передний план
    images.forEach((image) => {
      image.addEventListener('click', function () {
        images.forEach((img) => (img.style.zIndex = 1)); // Сбрасываем z-index у всех картинок
        this.style.zIndex = 10; // Выдвигаем выбранную картинку на передний план
      });
    });
  });
});


//Video Controlls
document.addEventListener("DOMContentLoaded", function () {
  // Находим все видео-контейнеры
  const videoContainers = document.querySelectorAll(".videoContainer");

  if (videoContainers.length === 0) {
    console.error("Видео-контейнеры не найдены.");
    return;
  }

  videoContainers.forEach(function (container) {
    const video = container.querySelector(".customVideo");
    const playPauseButton = container.querySelector(".playPauseButton");
    const playIcon = container.querySelector(".playIcon");
    const pauseIcon = container.querySelector(".pauseIcon");
    const muteButton = container.querySelector(".muteButton");
    const volumeIcon = container.querySelector(".volumeIcon");
    const muteIcon = container.querySelector(".muteIcon");

    // Проверяем, существуют ли все элементы
    if (!video || !playPauseButton || !muteButton || !playIcon || !pauseIcon || !volumeIcon || !muteIcon) {
      console.error("Не найдены все элементы управления для видео в контейнере:", container);
      return;
    }

    // Управление воспроизведением и паузой
    playPauseButton.addEventListener("click", function () {
      if (video.paused) {
        video.play();
        playIcon.style.display = "none";
        pauseIcon.style.display = "block";
      } else {
        video.pause();
        playIcon.style.display = "block";
        pauseIcon.style.display = "none";
      }
    });

    // Управление звуком (включение/выключение)
    muteButton.addEventListener("click", function () {
      video.muted = !video.muted;
      if (video.muted) {
        volumeIcon.style.display = "none";
        muteIcon.style.display = "block";
      } else {
        volumeIcon.style.display = "block";
        muteIcon.style.display = "none";
      }
    });
  });
});
